/*eslint-disable*/
import {
  getLocaleCurrencyCountryMap,
  NAGPCountryKeys,
  NAGPCountryKeyLocalCurrencyMap
} from '@/constants/nagp/NationalAssociationGoalPlanningConstants'
import Decimal from 'decimal.js'

/**
 * Formats a numeric string as currency based on the specified locale, currency, and fraction digits.
 *
 * @param {string} numberString - A numeric string in the format "1233.45" (always with two decimal places).
 * @param {string} locale - The locale identifier (e.g., "pt-BR") to determine number formatting.
 * @param {string} currency - The currency code (e.g., "BRL") for proper currency symbol and formatting.
 * @param {number} [fractionDigitsMin=0] - The minimum number of fraction digits to display.
 * @param {number} [fractionDigitsMax=0] - The maximum number of fraction digits to display.
 * @returns {string} The formatted currency string based on the provided locale, currency, and fraction digit settings.
 */
function formatCurrency (numberString, locale, currency, fractionDigitsMin = 0, fractionDigitsMax = 0) {
  Decimal.set({ precision: 100 });
  const numberValue = new Decimal(numberString.toString()).toDecimalPlaces(2).toFixed(2)
  // Formatter with currency
  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: fractionDigitsMin,
    maximumFractionDigits: fractionDigitsMax
  })

  // Get the currency symbol by formatting a number
  const parts = currencyFormatter.formatToParts(numberValue)
  const currencySymbolPart = parts.find(part => part.type === 'currency')
  const currencySymbol = (currencySymbolPart && currencySymbolPart.value) || ''

  // Formatter without currency symbol
  const numberFormatter = new Intl.NumberFormat(locale)
  // Formatter without currency symbol and with decimal
  const numberFormatterWithDecimal = new Intl.NumberFormat(locale, {
    minimumFractionDigits: fractionDigitsMin,
    maximumFractionDigits: fractionDigitsMax
  })

  return {
    currency,
    currencySymbol,
    formattedWithCurrency: currencyFormatter.format(numberValue).replace(/(\p{Sc})(\d)/u, '$1 $2'), // Add space after currency symbol if it's directly adjacent to the number
    formattedWithoutCurrency: numberFormatter.format(numberValue),
    formattedWithDecimalAndWithoutCurrency: numberFormatterWithDecimal.format(numberValue)
  }
}

function getNGAPFormatCurrencyByCountryKey (numberString, countryKey, maxAndMinFractionalDigits = 0) {
  const LocaleCurrencyCountryMap = getLocaleCurrencyCountryMap() // Get the current Locale Currency Map

  const { locale, currency } = LocaleCurrencyCountryMap.get(countryKey) || {}
  const data = formatCurrency(numberString, locale, currency, maxAndMinFractionalDigits, maxAndMinFractionalDigits)
  data.currencySymbolWithCurrencyCode = NAGPCountryKeyLocalCurrencyMap.get(countryKey)
  data.formattedWithCurrencyAndCurrencyCode = data.formattedWithCurrency.replace(
    data.currencySymbol,
    data.currencySymbolWithCurrencyCode
  )
  if (countryKey === NAGPCountryKeys.REPUBLIC_OF_CHINA) {
    const correctedCurrencySymbol = `NT${data.currencySymbol}`
    return {
      ...data,
      currencySymbol: correctedCurrencySymbol,
      formattedWithCurrency: `${correctedCurrencySymbol} ${data.formattedWithoutCurrency}`
    }
  }
  return data
}
function getNAGPCurrencySymbolByCountryKey (countryKey) {
  return NAGPCountryKeyLocalCurrencyMap.get(countryKey)
}

export { getNGAPFormatCurrencyByCountryKey, formatCurrency, getNAGPCurrencySymbolByCountryKey }
