
/**
 * Creates a dynamic regular expression that matches any of the specified characters.
 *
 * @param {string} chars - A string containing characters to be included in the regex.
 *                         Special characters are automatically escaped.
 * @param {string} [flags] - Optional regex flags (e.g., "g" for global, "i" for case-insensitive).
 * @returns {RegExp} A regular expression matching any of the provided characters.
 */
function getDynamicRegex (chars, flags = undefined) {
  let escapedChars = chars.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  return new RegExp(`[${escapedChars}]`, flags)
}

export { getDynamicRegex }
