<template>
  <input
    v-if="editable"
    :ref="inputElementId"
    type="text"
    @click="cursorHandler"
    v-bind="$attrs"
    @input="inputChangeHandler($event)"
    @blur="inputBlurHandler($event)"
    :value="formattedInputValue"
  />
  <label v-else v-bind="$attrs" @click="changeToEditModeHandler">
    {{ this.readonlyModeFormatter(this.inputValue) }}
  </label>
</template>
<script>
/*eslint-disable*/
import { FormatNumber } from '@/services/utils/FormatNumber.js'
export default {
  name: 'NAGP-goal-base-input',
  model: {
    prop: 'inputValue',
    event: 'input',
    default: null
  },
  props: {
    inputValue: {
      type: [String, Number],
      required: true
    },
    maximumDigitsAllowed: {
      type: Number,
      default: 6
    },
    inputNumberFormatter: {
      type: Function,
      default: FormatNumber
    },
    editable: {
      type: Boolean,
      default: true
    },
    readonlyModeFormatter: {
      type: Function,
      default: val => val
    }
  },
  data() {
    return {
      inputElementId: null
    }
  },
  computed: {
    formattedInputValue() {
      if (this.inputValue === '') return ''
      return this.inputNumberFormatter(this.deFormatToNumber(this.inputValue))
    }
  },
  methods: {
    changeToEditModeHandler() {
      this.$emit('editableMode', true)
      this.$nextTick(() => {
        // Trigger focus on the input once edit mode is on
        this.$refs[this.inputElementId].focus()
      })
    },
    inputBlurHandler(event) {
      this.$emit('blur', event)
      this.$emit('editableMode', false)
    },
    deFormatToNumber(numberWithCommas) {
      return String(numberWithCommas).replaceAll(',', '')
    },
    cursorHandler(event) {
      if (event.target.selectionStart === 0 && event.target.selectionEnd === 0) {
        const length = event.target.value.length
        event.target.setSelectionRange(length, length)
        event.target.focus()
      }
    },
    inputChangeHandler(event) {
      const matchPattern = /\D/g
      const inputValue = event.target.value
      const currentValue = inputValue.replaceAll(matchPattern, '')
      if (currentValue === '') {
        this.$emit('input', '')
        event.target.value = ''
        return
      }
      if (currentValue.toString().length > this.maximumDigitsAllowed) {
        this.$emit('input', this.inputValue)
        event.target.value = this.inputNumberFormatter(this.inputValue)
      } else {
        this.$emit('input', currentValue)
        event.target.value = this.inputNumberFormatter(currentValue)
      }
    }
  },
  created() {
    this.inputElementId = crypto.randomUUID()
  }
}
</script>
