/* eslint-disable  */
import axiosErrorHandler from '@/services/axiosErrorHandler.js'
import { GET_GOAL_API_ENDPOINT } from '@/config/goals.api.config.js'
import { NAGPShortCodeFormKeyMap } from '@/constants/nagp/NationalAssociationGoalPlanningConstants.js'
import store from '@/store.js'
import Decimal from 'decimal.js'

export default class NationalAssociationGoalPlanning {
  static TransformNAGPFormDataToDTO (formData) {
    const dto = {}
    dto.nag_barnabas_fund = formData.barnabasFund
    dto.nag_gideon_scripture = formData.gideonScripture
    dto.nag_auxiliary_scripture = formData.auxiliaryScripture
    dto.nag_admin_reciepts = formData.adminReceipts
    dto.nag_gideon_placements = formData.gideonPlacements
    dto.nag_auxiliary_placements = formData.auxiliaryPlacements
    dto.nag_gideons_pwt = formData.gideonPWT
    dto.nag_auxiliary_pwt = formData.auxiliaryPWT
    dto.nag_church_presentation = formData.churchPresentation
    dto.nag_gideons_membership = formData.gideonMembership
    dto.nag_auxiliary_membership = formData.auxiliaryMembership
    dto.nag_cnt_key = formData.countryKey
    dto.nag_fiscal_year = formData.fiscalYear
    dto.nag_modified_by = formData.userName
    return dto
  }
  static async AddOrUpdateNationalAssociationGoals (NAGPGoalsFormData) {
    const URL = GET_GOAL_API_ENDPOINT('AddOrUpdateNAGPGoals')()
    store.dispatch('menu/setLoadingStatus', true)
    const response = await axiosErrorHandler({
      endpoint: URL,
      apiType: 'post',
      payload: NationalAssociationGoalPlanning.TransformNAGPFormDataToDTO(NAGPGoalsFormData),
      successMessage: 'You have successfully updated your National Association goals.'
    })
    store.dispatch('menu/setLoadingStatus', false)
    return response
  }
  static TransformNAGPGoalDataForUI (NAGPGoalsDataResponse) {
    const { Goals: NAGPGoalsData, dashboardNagpData } = NAGPGoalsDataResponse
    try {
      Decimal.set({ precision: 100 })
      const temp = Object.groupBy(NAGPGoalsData, ({ Heading }) => Heading)

      const transformedFlatData = []
      const result = Object.entries(temp).map(([key, value]) => {
        //IMPORTANT: We are using Decimal here as goals values can be very large
        let FY1CategoryTotal = new Decimal(0.00)
        const goalsData = value.map(data => {

          try {

            const val = data.hasOwnProperty('FY1Goal')
              ? new Decimal(data.FY1Goal.toString())
              : 0.00
            FY1CategoryTotal = FY1CategoryTotal.plus(val)
          } catch (err) {
            console.error('NAGP_data_transformation_UI_goal_parsing_error', err)
            FY1CategoryTotal = FY1CategoryTotal.plus(0.00)
          }

          return {
            NAGoalName: data.Subheading,
            NAGoal: data.Value,
            NAFY1Goal: data.FY1Goal || 0,
            NAShortCode: data.ColumnName, //TODO: Update when API has updated with short code. Right now it is using column name which uniquely identify
            NAFormKey: NAGPShortCodeFormKeyMap.get(data.ColumnName) //TODO: Update when short code is ready
          }
        })
        transformedFlatData.push(goalsData)
        return {
          NAGoalCategory: key,
          NAGoalsData: goalsData,
          NAFY1GoalsTotalByCategory: FY1CategoryTotal.toString()
        }
      })

      return {
        transformedFlatData: transformedFlatData.flat(), transformedDataForUI: result, dashboardNagpData
      }
    } catch (err) {
      console.error('obj_transformation_error:', err)
      return NAGPGoalsData
    }
  }

  static async GetNationalAssociationGoals (countryKey) {
    const URL = GET_GOAL_API_ENDPOINT('GetNAGPGoals')(countryKey)
    store.dispatch('menu/setLoadingStatus', true)
    const response = await axiosErrorHandler({
      endpoint: URL,
      apiType: 'get'
    })
    store.dispatch('menu/setLoadingStatus', false)

    return NationalAssociationGoalPlanning.TransformNAGPGoalDataForUI(response)
  }

  static async LockNationalAssociationGoals (payload) {
    const URL = GET_GOAL_API_ENDPOINT('LockUnlockNAGPGoals')()
    store.dispatch('menu/setLoadingStatus', true)
    const response = await axiosErrorHandler({
      endpoint: URL,
      apiType: 'put',
      payload,
      successMessage: `You have successfully ${payload.nag_isLocked ? 'locked' : 'unlocked'} your National Association goals.`
    })
    store.dispatch('menu/setLoadingStatus', false)
    return response
  }


  static async GetNationalAssociationGoalsCountry () {
    const URL = GET_GOAL_API_ENDPOINT('GetCountriesForNAGP')()
    store.dispatch('menu/setLoadingStatus', true)
    const response = await axiosErrorHandler({
      endpoint: URL,
      apiType: 'get'
    })
    store.dispatch('menu/setLoadingStatus', false)
    return response
  }
}
